import { Component, ElementRef, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, ViewChild, HostListener, Renderer2 } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { Entry } from 'contentful';

import { ctfConfig } from '@src/config/contentful.config';

import { AnalyticsService } from '@src/app/shared/services/fb-analytics.service';
import { ContentService } from '@src/app/shared/services/content.service';
import { GeneralService } from '@src/app/shared/services/general.service';
import { HtmlElementService } from '@src/app/shared/services/html-element.service';
import { deferState, aboutState } from '@src/app/shared/animations/animations';

@Component({
  selector: 'nh-home-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [
    aboutState,
    deferState
  ]
})

export class AboutComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('aboutMain') aboutMainHTML!: ElementRef;

  private aboutID: string = ctfConfig.aboutID;
  
  aboutState: boolean = true;
  about: Entry<any> | null;
  aboutSub: Subscription;

  aboutTop: number;
  aboutHeight: number;
  aboutImgGrid: any[] = Array.apply(null, Array(8)).map(()=>{});

  forceLoad: boolean;
  topPos: number;

  constructor(
    private rend: Renderer2,
    protected analyticsService: AnalyticsService,
    protected contentService: ContentService,
    private generalService: GeneralService,
    private HtmlElementService: HtmlElementService,
    private aboutHTML: ElementRef
  ) { }

  @HostListener(
    'window:scroll', ['$event']
  )
  handleScroll(): void {
    this.setAboutTop();
  }

  @HostListener(
    'window:resize', ['$event']
  )
  handleResize(): void {
    this.setAboutTop();

    if (this.aboutState) {
      const aboutEl = this.aboutMainHTML.nativeElement;

      if (this.generalService.isMobScrn)
        this.generalService.vhFix100(aboutEl);

      if (!this.generalService.isMobScrn)
        this.generalService.vhFix100(aboutEl, false);
    }
  }

  selectAbout($event?: any): void {
    // this.aboutState = true;

    this.aboutSub = this.contentService.getEntry(
      this.aboutID, 
      'about'
    )
    .subscribe((data) => {
      this.about = data;
      this.aboutState = true;
    });

    if (this.generalService.isMobScrn) {
      const scrollPos = window.scrollY,
            winHeight = window.innerHeight,
            aboutRecs = this.aboutHTML.nativeElement.getClientRects()[0];

      this.rend.setStyle(document.body, 'overflow', 'hidden');
      
      if (aboutRecs.top > 0) 
        return this.generalService.scrollSnap(
          null,
          200,
          $event,
          scrollPos + aboutRecs.top
        );

      if (aboutRecs.bottom < winHeight)
        this.generalService.scrollSnap(
          null,
          200,
          $event,
          scrollPos + aboutRecs.bottom - winHeight
        );
    }
  }

  setAboutTop(): void {
    if (this.generalService.isMobScrn) {
      const aboutMainRecs = this.aboutMainHTML?.nativeElement.getClientRects()[0];

      this.aboutTop = 0;

      if (this.aboutState) {
        if (aboutMainRecs.top === 0) {
          this.rend.setStyle(document.body, 'overflow', 'hidden');
          return;
        }

        this.aboutMainHTML.nativeElement.scrollIntoView();
      }

      this.rend.removeStyle(document.body, 'overflow');
      return;
    }

    this.rend.removeStyle(document.body, 'overflow');
    this.aboutTop = (window.innerHeight - this.aboutHeight) / 2;
  }

  closeAbout($event: any, bubble: boolean = true): void {
    if (
      !bubble &&
      $event.currentTarget !== $event.target
    )
      return;
    
    this.analyticsService.trackInput($event);
      
    if (document.body.style.overflow === 'hidden')
      this.rend.removeStyle(document.body, 'overflow');

    this.aboutState = false;
    this.aboutSub.unsubscribe();
  }

  // Lifecycle Hooks

  ngOnInit(): void {
    this.aboutSub = this.contentService.getEntry(
      this.aboutID, 
      'about',
      false
    )
    .subscribe((data) => {
      const bgImages = data.fields.bgImageGrid;

      this.about = data;

      this.aboutImgGrid.map((e, i, arr) => {
        bgImages.map((e2) => {
          if (i === e2.fields.imgFileIndex) {
            arr.splice(i, 1, e2);
          }
        });
      });
    });
  }

  ngAfterViewInit(): void {
    this.topPos = this.aboutHTML.nativeElement.offsetTop;

    this.HtmlElementService.setElement(
      'about', 
      this.aboutHTML.nativeElement
    );
  }

  ngAfterViewChecked(): void {
    if (!this.forceLoad) {
      this.topPos = this.aboutHTML.nativeElement.offsetTop;

      if (this.topPos <= this.generalService.scrollElTopPos) {
        this.forceLoad = this.generalService.forceLoad;
      }
    }

    if (this.about && this.aboutState) {
      const aboutEl = this.aboutMainHTML.nativeElement,
            aboutHeight = aboutEl.offsetHeight;

      if (this.generalService.isMobScrn)
        this.generalService.vhFix100(aboutEl);

      if (!this.generalService.isMobScrn)
        this.generalService.vhFix100(aboutEl, false);

      if (!this.aboutHeight) {
        this.aboutHeight = aboutHeight;
        this.setAboutTop();
        this.aboutState = false;
        return;
      }

      if (this.aboutHeight !== aboutHeight) 
        this.aboutHeight = aboutHeight;
    }
  }

  ngOnDestroy(): void {
    this.aboutSub.unsubscribe();
    this.HtmlElementService.deleteElement('about');
  }
}

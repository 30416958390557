<div class="striped-bg"></div>
<section id="home-about">
	<div class="no-overflow">
		<div class="section-main">
			@defer (on viewport; when forceLoad) {
				<div id="home-about-top" @deferState>
					<h2 [innerHTML]="about?.fields?.title || 'Something went wrong.'"></h2>
				</div>
			} @placeholder {
				<div class="defer-ph"></div>
			}
			@defer (on viewport; when forceLoad) {
				<div *ngIf="about.fields; else noAbout" id="home-about-main" @deferState>
					<div id="about-info-container">
						<div id="about-info-main">
							<h2 [innerHTML]="about.fields.subtitle"></h2>
							<p [innerHTML]="contentService.docToHTML(about.fields.entry).split('<!--intro-end-->')[0]"></p>
							<div class="entry-btn-wrap">
								<a class="button" href="#" (click)="selectAbout($event); false;">Full Bio</a>
							</div>
						</div>
					</div>
					<div id="about-img-wrap" [style.background-image]="'url(' + about.fields.backgroundImage.fields.file.url + ')'">
						<ul id="about-img-grid">
							<li 
								*ngFor="let item of aboutImgGrid" 
								[style.background-image]="item ? 'url(' + item.fields.imgFile.fields.file.url + ')' : null"
								[style.background-color]="item ? item.fields.imgFileBgColor : null"
								[style]="item ? item.fields.imgFileCSS : null"
							>
							</li>
						</ul>
					</div>
				</div>
				<ng-template #noAbout>
					<div *ngIf="!about.fields" id="no-about">
						<p>Sorry, about section not found.</p>
					</div>
				</ng-template>
			} @placeholder {
				<div class="defer-ph"></div>
			}
			@defer (on viewport; when forceLoad) {
				<div id="home-about-bottom" @deferState>
					<h2>Let&rsquo;s make it official</h2>
					<p>Follow me on these platforms <br />(or take a look at my r&eacute;sum&eacute;)  below:</p>
					<ul id="about-social">
						<li><a href="https://www.linkedin.com/in/nickhudson1" target="_blank" (click)="analyticsService.trackInput($event);"><img src="../../../assets/images/linkedin-tan-001.svg" width="70" alt="LinkedIn"></a></li>
						<li><a href="https://www.instagram.com/nickhudsondev/" target="_blank" (click)="analyticsService.trackInput($event);"><img src="../../../assets/images/instagram-tan-001.svg" width="70" alt="Instagram"></a></li>
						<li><a href="https://www.behance.net/nickhudson86" target="_blank" (click)="analyticsService.trackInput($event);"><img src="../../../assets/images/behance-tan-001.svg" width="70" alt="Behance"></a></li>
						<li><a href="https://assets.ctfassets.net/1sl9m3guoua9/55VKR61YMr6sfC5HlvLXJW/72e6ce3d83a689570b8716ea565882e7/Nick-Hudson-Resume-2311.pdf" target="_blank" (click)="analyticsService.trackInput($event);"><img src="../../../assets/images/resume-tan-001.svg" width="70" alt="My R&eacute;sum&eacute;"></a></li>
					</ul>
				</div>
			} @placeholder {
				<div class="defer-ph"></div>
			}
		</div>
	</div>
	<div class="clearfix"></div>
	<div *ngIf="about.fields && aboutState" [@aboutState]="aboutState" id="ha-full" [attr.data-content]="about.fields.title" (click)="closeAbout($event, false);">
		<div #aboutMain [ngStyle]="{'top': aboutTop + 'px'}" id="ha-full-main">
			<div id="hafm-top">
				<a class="about-close" [attr.data-content]="about.fields.title" href="#" (click)="closeAbout($event); false;"><img src="../../../../assets/images/close-gold-001.svg" width="40" alt="About Close"></a>
			</div>
			<div class="clearfix"></div>
			<div class="about-main">
				<h3 [innerHTML]="about.fields.subtitle"></h3>
				<p [innerHTML]="contentService.docToHTML(about.fields.entry)"></p>
			</div>
		</div>
	</div>
</section>
import { animation, state, style, animate, trigger, transition, useAnimation, AnimationTriggerMetadata } from '@angular/animations';

function entriesTrigger(triggerName: string = 'entriesState'): AnimationTriggerMetadata {
	return trigger(triggerName, [
		transition(':enter', [
			style({ opacity: 0 }),
			animate('300ms ease-out', style({
				opacity: 1
			})),
		]),
		transition(':leave', [
			animate('300ms ease-out', style({
				opacity: 0
			}))
		])
	])
}

export const deferState = trigger('deferState', [
	transition(':enter', [
		style({ opacity: 0, transform: 'translateY(200px)' }),
		animate('400ms 200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
	])
]);

export const cookieState = trigger('cookieState', [
	state('show', style({
		opacity: 1,
		visibility: 'visible'
	})),
	state('hide', style({
		opacity: 0,
		visibility: 'hidden'
	})),
	transition('show <=> hide', animate('450ms ease-out'))
]);

export const entriesState = entriesTrigger();
export const entryState = entriesTrigger('entryState');
export const aboutState = entriesTrigger('aboutState');
export const submitState = entriesTrigger('submitState');